import { getProfile } from '../../services/Api';

export const usuario = getProfile()?.usuario;
export const empresa = getProfile()?.empresa;
export const filial = getProfile()?.filial;

export function SchemaCFOP() {
    this.id = undefined;
    this.codigo = '';
    this.descricao = '';
    this.tipo = undefined;
}

export function SchemaComplementoNatureza() {
    this.id = undefined;
    this.empresa = { id: empresa };
    this.codigoCFOP = undefined;
    this.numero = undefined;
    this.descricao = '';
    this.movimentoFinanceiro = 'SIM';
    this.prazoVista = undefined;
    this.status = 'ATIVO';
}

export function SchemaFinalidadeFiscal() {
    this.id = undefined;
    this.empresa = { id: empresa };
    this.operacaoEstoque = undefined;
    this.descricao = '';
    this.indicadorPresenca = undefined;
    this.finalidadeEmissao = undefined;
    this.modalidadeFrete = undefined;
    this.status = 'ATIVO';
}

export function SchemaCodigoAjusteIcms() {
    this.id = undefined;
    this.codigo = '';
    this.descricao = '';
}

export function SchemaComentarioFiscal() {
    this.id = undefined;
    this.descricao1 = '';
    this.descricao2 = '';
    this.diferimento = 'NÃO';
    this.suspenso = 'NÃO';
    this.isento = 'NÃO';
    this.baseCalculoReduzida = 'NÃO';
    this.ipi = 'NÃO';
    this.fiscal = 'NÃO';
    this.status = 'ATIVO';
}

export function SchemaItemAliquotaUF() {
    this.id = undefined;
    this.aliquotaUF = undefined;
    this.Date = undefined;
    this.tributacao = undefined;
    this.comentarioFiscal = undefined;
    this.comentarioPisCofins = undefined;
    this.codigoAjusteICMS = undefined;
    this.aliquotaIcms = 0;
    this.percentualReducaoIcms = 0;
    this.reducaoBaseIcms = 0;
    this.pauta = 'NÃO';
    this.valorPauta = 0;
    this.aliquotaIcmsTransferencia = 0;
    this.dataCadastro = undefined;
}

export function SchemaAliquotaUF() {
    this.id = undefined;
    this.usuario = {id: usuario};
    this.empresa = { id: empresa };
    this.filial = undefined;
    this.finalidade = undefined;
    this.codigoCFOP = undefined;
    this.complementoNatureza = undefined;
    this.regiao = undefined;
    this.estado = undefined;
    this.categoria = undefined;
    this.subCategoriaProduto = undefined;
    this.produto = undefined;
    this.codigoCfopDestino = undefined;
    this.complementoNaturezaDestino = undefined;
    this.codigoCofins = undefined;
    this.codigoPis = undefined;
    this.status = 'ATIVO';
    this.dataCadastro = undefined;
    this.itens = [];
}