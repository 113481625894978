import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { TabView, TabPanel } from 'primereact/tabview';

import confirmService from '../../../services/confirmService';
import Loading from '../../../components/loading';
import Toasty from '../../../components/toasty';
import Api from '../../../services/Api';

import { SchemaAliquotaUF, SchemaItemAliquotaUF, empresa } from '../LivModel';
const url = 'aliquotasUF';

const tiposVenda = [
    { label: 'PRAZO', value: 'PRAZO' },
    { label: 'VISTA', value: 'VISTA' }
];

const check = [
    { label: 'SIM', value: 'SIM' },
    { label: 'NÃO', value: 'NÃO' }
];

const tiposStatus = [
    { label: 'ATIVO', value: 'ATIVO' },
    { label: 'INATIVO', value: 'INATIVO' }
];

function AliquotaUF() {

    const [cfop, setCfop] = useState('');
    const [status, setStatus] = useState('');
    const [categoria, setCategoria] = useState('');
    const [finalidade, setFinalidade] = useState('');
    const [subCategoria, setSubCategoria] = useState('');
    const [codigoProduto, setCodigoProduto] = useState('');

    const [subCategoriasFiltro, setSubCategoriasFiltro] = useState([]);

    const [rows, setRows] = useState(5);
    const [page, setPage] = useState(0);
    const [first, setFirst] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const [cfops, setCfops] = useState([]);
    const [regioes, setRegioes] = useState([]);
    const [estados, setEstados] = useState([]);
    const [filiais, setFiliais] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [finalidades, setFinalidades] = useState([]);
    const [subCategorias, setSubCategorias] = useState([]);
    const [complementos, setComplementos] = useState([{ value: null, label: null }]);

    const [aliquotas, setAliquotas] = useState([]);
    const [aliquota, setAliquota] = useState(new SchemaAliquotaUF());

    useEffect(() => {
        document.title = 'Evolution Sistemas - eDigital | Alíquota UF';
    }, []);

    useEffect(() => {
        buscarCfops();
        buscarRegioes();
        buscarEstados();
        buscarFiliais();
        buscarCategorias();
        buscarFinalidades();
    }, []);

    async function buscarRegioes() {
        await Api({
            method: 'get',
            url: 'regioes',
        }).then(resp => {
            const data = resp.data.map((e) => ({
                value: e.id,
                label: e.descricao
            }));

            setRegioes(data);
        })
            .catch(error => {
                console.log(error);
            });
    }

    async function buscarEstados() {
        await Api({
            method: 'get',
            url: 'estados?resumo',
        }).then(resp => {
            const data = resp.data.content.map((e) => ({
                value: e.id,
                label: `${e.nomeEstado} - ${e.nomePais}`
            }));

            setEstados(data);
        })
            .catch(error => {
                console.log(error);
            });
    }

    async function buscarCfops() {
        await Api({
            method: 'get',
            url: `codigosCFOP?resumo`,
            params: {
                page: 0,
                size: 999999999,
            }
        }).then(resp => {

            const data = resp.data.content.map((e) => ({
                value: e.id,
                label: `${e.codigo} - ${e.descricao} - ${e.tipo}`
            }));

            setCfops(data);
        })
            .catch(error => {
                console.log(error);
            });
    }

    async function buscarCategorias() {
        await Api({
            method: 'get',
            url: `categoriasProduto?resumo`,
            params: {
                page: 0,
                empresa,
                size: 999999999
            }
        }).then(resp => {
            const data = resp.data.content.map((e) => ({
                value: e.id,
                label: `${e.codigo} - ${e.descricao}`
            }));

            setCategorias(data);
        })
            .catch(error => {
                console.log(error);
            });
    }

    function onChangeCategoria(value) {
        buscarSubCategorias(1, value);
        setAliquota({ ...aliquota, subCategoriaProduto: null, categoria: { id: value } })
    }

    function onChangeCategoriaFiltro(value) {
        setCategoria(value);
        setSubCategoria(null);
        buscarSubCategorias(0, value);
    }

    async function buscarSubCategorias(index, categoria) {
        await Api({
            method: 'get',
            url: `subCategoriasProduto?resumo`,
            params: {
                page: 0,
                empresa,
                categoria,
                size: 999999999,
            }
        }).then(resp => {
            const data = resp.data.content.map((e) => ({
                value: e.id,
                label: `${e.codigo} - ${e.descricao}`
            }));

            if (index === 0) {
                setSubCategoriasFiltro(data);
            } else {
                setSubCategorias(data);
            }

        })
            .catch(error => {
                console.log(error);
            });
    }

    async function buscarFinalidades() {

        await Api({
            method: 'get',
            url: `finalidadesFiscal?resumo`,
            params: {
                empresa,
                page: 0,
                size: 999999999,
            }
        }).then(resp => {
            const data = resp.data.content.map((e) => ({
                value: e.id,
                label: `${e.descricao} - ${e.tipo}`
            }));

            setFinalidades(data);
        })
            .catch(error => {
                console.log(error);
            });
    }

    async function buscarFiliais() {

        await Api({
            method: 'get',
            url: `filiais?resumo`,
            params: {
                empresa,
                page: 0,
                size: 999999999,
            }
        }).then(resp => {
            const data = resp.data.content.map((e) => ({
                value: e.id,
                label: `${e.codigo} - ${e.nome}`
            }));

            setFiliais(data);
        })
            .catch(error => {
                console.log(error);
            });
    }

    function onChangeCFOP(value) {
        buscarComplementos(value);
        setAliquota({ ...aliquota, complementoNatureza: null, codigoCFOP: { id: value } });
    }

    async function buscarComplementos(cfop) {
        await Api({
            method: 'get',
            url: `complementosNatureza?resumo`,
            params: {
                cfop,
                empresa,
                page: 0,
                size: 99999999,
            }
        }).then(resp => {
            const data = resp.data.content.map((e) => ({
                value: e.id,
                label: `${e.numero} - ${e.descricao}`
            }));

            setComplementos(data);
        })
            .catch(error => {
                console.log(error);
            });
    }

    function onPage(event) {
        pesquisar(event.page, event.rows, event.first)
            .then(
                setFirst(event.first),
                setRows(event.rows),
                setPage(event.page))
            .catch(error => {
                console.log(error);
            });
    }

    async function buscarPorId(value) {
        Loading.onShow();

        await Api({
            method: 'get',
            url: `${url}/${value}`
        }).then(resp => {
            const { codigoCFOP } = resp.data;

            if (!!codigoCFOP) {
                buscarComplementos(codigoCFOP?.id);
            }

            Loading.onHide();

            setActiveIndex(1);
            setAliquota(resp.data);
        })
            .catch(error => {
                Loading.onHide();
                Toasty.error('Erro!', 'Erro ao buscar registros!');
            });
    }

    async function pesquisar(eventPage = 0, eventRows = 0, eventFirst = 0) {
        Loading.onShow();

        await Api({
            method: 'get',
            url: `${url}?resumo`,
            params: {
                cfop,
                status,
                empresa,
                categoria,
                finalidade,
                subCategoria,
                codigoProduto,
                size: eventRows,
                page: eventPage,
            }
        }).then(resp => {
            Loading.onHide();

            setFirst(eventFirst);
            setAliquotas(resp.data.content);
            setTotalRecords(resp.data.totalElements);
        })
            .catch(error => {
                Loading.onHide();
                console.log(error);
                Toasty.error('Erro!', 'Erro ao buscar registros!');
            });
    }

    async function excluir(value) {
        Loading.onShow();

        await Api({
            method: 'delete',
            url: `${url}/${value}`
        }).then(resp => {
            Loading.onHide();
            Toasty.success('Sucesso!', 'Registro excluído com sucesso!');
            pesquisar();
        })
            .catch(error => {
                Loading.onHide();
                console.log(error);
                Toasty.error('Erro!', 'Erro ao excluír registros!');
            });
    }

    async function salvar() {
        Loading.onShow();

        if (aliquota.id > 0) {
            await Api({
                method: 'put',
                url: `${url}/${aliquota.id}`,
                data: JSON.stringify(aliquota)
            }).then(resp => {
                Loading.onHide();
                cancelar();
                pesquisar();
                Toasty.success('Sucesso!', 'Aliquota por UF editado com sucesso!');
            })
                .catch(error => {
                    Loading.onHide();
                    console.log(error);
                    Toasty.error('Erro!', 'Erro ao processar esse registro!');
                });

        } else {
            await Api({
                method: 'post',
                url: `${url}`,
                data: JSON.stringify(aliquota)
            }).then(resp => {
                Loading.onHide();
                cancelar();
                pesquisar();
                Toasty.success('Sucesso!', 'Aliquota por UF adicionado com sucesso!');
            })
                .catch(error => {
                    Loading.onHide();
                    console.log(error);
                    Toasty.error('Erro!', 'Erro ao processar esse registro!');
                });
        }
    }

    async function confirmacaoExcluir(value) {
        await confirmService.show({
            message: `Deseja realmente excluír esse registro (${value}) ?`
        }).then(
            (res) => {
                if (res) {
                    excluir(value);
                }
            }
        ).catch(error => {
            console.log(error)
        });
    }

    function validaFormulario() {
        try {
            // if (complemento.codigoCFOP?.id &&
            //     complemento.descricao.length >= 4 &&
            //     complemento.numero) {
            //     return true;
            // } else {
            //     return false;
            // }
        } catch (error) {
            console.log(error)
            return false;
        }
    }

    function inserir() {
        setActiveIndex(1);
        setAliquota(new SchemaAliquotaUF());
        setComplementos([{ value: null, label: null }]);
    }

    function cancelar() {
        setActiveIndex(0);
    }

    function exportarXLS() {
        Toasty.warn('Atenção!', 'Falta implementação!');
    }

    function acoesTabela(rowData) {
        return <div>
            <Button
                type='button'
                tooltip='Editar'
                icon='pi pi-pencil'
                className='p-button-warning'
                style={{ marginRight: '.5em' }}
                tooltipOptions={{ position: 'left' }}
                onClick={() => buscarPorId(rowData.id)}
            />
            <Button
                type='button'
                tooltip='Excluir'
                icon='pi pi-trash'
                className='p-button-danger'
                tooltipOptions={{ position: 'left' }}
                onClick={() => confirmacaoExcluir(rowData.id)}
            />
        </div>;
    }

    const header = <div className='p-clearfix' style={{ lineHeight: '1.87em' }}>Lista de Alíquota por UF</div>;

    const footer = 'Quantidade de registros ' + totalRecords;

    return (
        <div className='p-fluid'>
            <div className='p-grid'>
                <div className='p-col-12'>
                    <Card>
                        <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                            <TabPanel disabled header='Lista'>
                                <Toolbar>
                                    <div className='p-toolbar-group-left'>
                                        <Button
                                            icon='pi pi-plus'
                                            label='Adicionar'
                                            onClick={inserir}
                                            className='p-button-success'
                                        />
                                    </div>
                                    <div className='p-toolbar-group-right'>
                                        <Button
                                            tooltip='Pesquisar'
                                            icon='pi pi-search'
                                            onClick={() => pesquisar()}
                                            style={{ marginRight: '.25em' }}
                                            tooltipOptions={{ position: 'left' }}
                                        />

                                        <Button
                                            type='button'
                                            tooltip='XLS'
                                            icon='pi pi-external-link'
                                            className='p-button-warning'
                                            onClick={() => exportarXLS()}
                                            disabled={cfops.length === 0}
                                            tooltipOptions={{ position: 'left' }}
                                        />
                                    </div>
                                </Toolbar>

                                <div className='p-grid' style={{ marginTop: 5, flexDirection: 'row' }}>
                                    <div className='p-col-12 p-md-6'>
                                        <label htmlFor='in' style={{ fontWeight: 'bold' }}>CFOP</label>
                                        <Dropdown
                                            filter={true}
                                            value={cfop}
                                            showClear={true}
                                            options={cfops}
                                            filterBy='label,value'
                                            onChange={(e) => setCfop(e.value)}
                                        />
                                    </div>
                                    <div className='p-col-12 p-md-6'>
                                        <label htmlFor='in' style={{ fontWeight: 'bold' }}>Categoria</label>
                                        <Dropdown
                                            filter={true}
                                            value={categoria}
                                            showClear={true}
                                            options={categorias}
                                            filterBy='label,value'
                                            onChange={(e) => onChangeCategoriaFiltro(e.value)}
                                        />
                                    </div>
                                    <div className='p-col-12 p-md-4'>
                                        <label htmlFor='in' style={{ fontWeight: 'bold' }}>SubCategoria</label>
                                        <Dropdown
                                            filter={true}
                                            showClear={true}
                                            value={subCategoria}
                                            filterBy='label,value'
                                            options={subCategoriasFiltro}
                                            onChange={(e) => setSubCategoria(e.value)}
                                        />
                                    </div>
                                    <div className='p-col-12 p-md-4'>
                                        <label htmlFor='in' style={{ fontWeight: 'bold' }}>Finalidade</label>
                                        <Dropdown
                                            filter={true}
                                            showClear={true}
                                            value={finalidade}
                                            filterBy='label,value'
                                            options={finalidades}
                                            onChange={(e) => setFinalidade(e.value)}
                                        />
                                    </div>
                                    <div className='p-col-12 p-md-2'>
                                        <label htmlFor='in' style={{ fontWeight: 'bold' }}>Código Produto</label>
                                        <InputText
                                            maxLength={10}
                                            value={codigoProduto}
                                            onChange={(e) => setCodigoProduto(e.target.value)}
                                        />
                                    </div>
                                    <div className='p-col-12 p-md-2'>
                                        <label htmlFor='in' style={{ fontWeight: 'bold' }}>Status</label>
                                        <Dropdown
                                            filter={true}
                                            value={status}
                                            showClear={true}
                                            options={tiposStatus}
                                            filterBy='label,value'
                                            onChange={(e) => setStatus(e.value)}
                                        />
                                    </div>
                                </div>

                                <div className='content-section implementation'>
                                    <DataTable
                                        lazy={true}
                                        rows={rows}
                                        first={first}
                                        header={header}
                                        footer={footer}
                                        onPage={onPage}
                                        paginator={true}
                                        responsive={true}
                                        value={aliquotas}
                                        style={{ marginTop: 10 }}
                                        totalRecords={totalRecords}
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        emptyMessage={'Nenhum registro encontrado!'}
                                    >
                                        <Column field='id' header='ID' style={{ width: '6em' }} />
                                        <Column field='cfop' header='CFOP' style={{ width: '6em' }} />
                                        <Column field='categoria' header='Categoria' style={{ width: '7em' }} />
                                        <Column field='subCategoria' header='SubCategoria' style={{ width: '8em' }} />
                                        <Column field='codigoProduto' header='Código' style={{ width: '8em' }} />
                                        <Column field='produto' header='Produto' />
                                        <Column field='regiao' header='Região' style={{ width: '7em' }} />
                                        <Column field='status' header='Status' style={{ width: '6em' }} />
                                        <Column body={acoesTabela} style={{ textAlign: 'center', width: '8em' }} />
                                    </DataTable>
                                </div>
                            </TabPanel>

                            <TabPanel disabled header='Cadastro'>
                                <h2 style={{ margin: 5 }}>Cadastro de Alíquota por UF</h2>
                                <div className='p-fluid'>
                                    <div className='p-col-12'>
                                        <div className='p-grid'>
                                            <div className='p-col-12 p-md-2'>
                                                <label htmlFor='in' style={{ fontWeight: 'bold' }}>ID</label>
                                                <InputText
                                                    readOnly={true}
                                                    value={aliquota.id}
                                                />
                                            </div>
                                            <div style={{ padding: 0 }} className='p-md-10'></div>
                                            <div className='p-col-12 p-md-6'>
                                                <label htmlFor='in' style={{ fontWeight: 'bold' }}>Filial</label>
                                                <Dropdown
                                                    filter={true}
                                                    options={filiais}
                                                    filterBy='label,value'
                                                    value={aliquota.filial?.id}
                                                    onChange={(e) => setAliquota({ ...aliquota, filial: { id: e.value } })}
                                                />
                                            </div>
                                            <div className='p-col-12 p-md-6'>
                                                <label htmlFor='in' style={{ fontWeight: 'bold' }}>Finalidade</label>
                                                <Dropdown
                                                    filter={true}
                                                    options={finalidades}
                                                    filterBy='label,value'
                                                    value={aliquota.finalidade?.id}
                                                    onChange={(e) => setAliquota({ ...aliquota, finalidade: { id: e.value } })}
                                                />
                                            </div>
                                            <div className='p-col-12 p-md-8'>
                                                <label htmlFor='in' style={{ fontWeight: 'bold' }}>CFOP</label>
                                                <Dropdown
                                                    filter={true}
                                                    options={cfops}
                                                    filterBy='label,value'
                                                    value={aliquota.codigoCFOP?.id}
                                                    onChange={(e) => onChangeCFOP(e.value)}
                                                />
                                            </div>
                                            <div className='p-col-12 p-md-4'>
                                                <label htmlFor='in' style={{ fontWeight: 'bold' }}>Complemento</label>
                                                <Dropdown
                                                    filter={true}
                                                    filterBy='label,value'
                                                    options={complementos}
                                                    value={aliquota.complementoNatureza?.id}
                                                    onChange={(e) => setAliquota({ ...aliquota, complementoNatureza: { id: e.value } })}
                                                />
                                            </div>
                                            <div className='p-col-12 p-md-2'>
                                                <label htmlFor='in' style={{ fontWeight: 'bold' }}>Região</label>
                                                <Dropdown
                                                    filter={true}
                                                    options={regioes}
                                                    filterBy='label,value'
                                                    value={aliquota.regiao?.id}
                                                    onChange={(e) => setAliquota({ ...aliquota, regiao: { id: e.value } })}
                                                />
                                            </div>
                                            <div className='p-col-12 p-md-2'>
                                                <label htmlFor='in' style={{ fontWeight: 'bold' }}>Estado</label>
                                                <Dropdown
                                                    filter={true}
                                                    options={estados}
                                                    filterBy='label,value'
                                                    value={aliquota.estado?.id}
                                                    onChange={(e) => setAliquota({ ...aliquota, estado: { id: e.value } })}
                                                />
                                            </div>
                                            <div className='p-col-12 p-md-4'>
                                                <label htmlFor='in' style={{ fontWeight: 'bold' }}>Categoria</label>
                                                <Dropdown
                                                    filter={true}
                                                    options={categorias}
                                                    filterBy='label,value'
                                                    value={aliquota.categoria?.id}
                                                    onChange={(e) => onChangeCategoria(e.value)}
                                                />
                                            </div>
                                            <div className='p-col-12 p-md-4'>
                                                <label htmlFor='in' style={{ fontWeight: 'bold' }}>SubCategoria</label>
                                                <Dropdown
                                                    filter={true}
                                                    filterBy='label,value'
                                                    options={subCategorias}
                                                    value={aliquota.subCategoriaProduto?.id}
                                                    onChange={(e) => setAliquota({ ...aliquota, subCategoriaProduto: { id: e.value } })}
                                                />
                                            </div>


                                            <div className='p-col-12 p-md-12'>
                                                <Toolbar>
                                                    <div className='p-toolbar-group-left'>
                                                        <Button
                                                            label='Salvar'
                                                            onClick={salvar}
                                                            icon='pi pi-check'
                                                            disabled={validaFormulario()}
                                                        />
                                                    </div>
                                                    <div className='p-toolbar-group-right'>
                                                        <Button
                                                            label='Cancelar'
                                                            icon='pi pi-times'
                                                            onClick={cancelar}
                                                            className='p-button-danger'
                                                        />
                                                    </div>
                                                </Toolbar>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </Card>
                </div>
            </div>
        </div >
    );
}

export default withRouter(AliquotaUF);